.batchgeo-geolocation-controls {
	@include flexbox;
	@include flex-direction(column);
	position: absolute;
	bottom: 30px;
	z-index: 2;
	&.right {
		right: 20px;
	}
	&.left {
		left: 80px;
	}
	.geolocation-control {
		@include flexbox;
		@include justify-content(center);
		@include align-items(center);
		height: 50px;
		margin:0;
		background: #fff;
		cursor: pointer;
		color: #909090;
		&.round {
			width: 50px;
			padding: 0;
			border: 1px solid #D9D9D9;
			border-radius: 100%;
		}
		&.location {
			margin-top: 10px;
		}
		&.recenter {
			font-size: 0.9rem;
			font-weight: bold;
			width: auto;
			padding: 0 20px;
		}
		&:focus {
			outline: none;
		}
		&.active {
			background: #4687F1;
			.geolocation-control-icon {
				opacity: 1;
				filter: alpha(opacity=100);
				&:before {
					color:#f2f2f2;
				}
			}
		}
		.geolocation-control-icon {
			font-size: 32px;
			opacity:1 !important;
			color:#666666;
			display: block;
			margin:0 auto;
		}
	}
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
	from {
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

// style changes for mobile controls when geolocation is working
.waiting {
	.geolocation-control.active {
		.geolocation-control-icon {
			border: 2px solid #FFFFFF;
			border-top: 2px solid transparent;
		}
	}
	.geolocation-control-icon {
		-webkit-animation: rotating 2s linear infinite;
		-moz-animation: rotating 2s linear infinite;
		-ms-animation: rotating 2s linear infinite;
		-o-animation: rotating 2s linear infinite;
		animation: rotating 2s linear infinite;
		width: 15px;
		height: 15px;
		border: 2px solid #666666;
		border-left: 2px solid transparent;
		border-radius: 100%;
		&:before {
			content: "" !important;
		}
	}
}
