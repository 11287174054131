.storeLocator-view {
	#page {
		display: none;
	}
}

body.storeLocator-view {
	overflow: hidden;

	@include media("screen", ">tablet")  {
		#topBar.in-iframe {
			height: 0;
			#utility {
				display: none;
			}
			#map-title {
				display: none;
			}	
		}
	}
}
.storeLocator {
	position: absolute;
	left: 0;
	z-index: 0;
	overflow: hidden;
	background: #fff;
	width: 375px;
	border: 1px solid #d0d0d0;
	border-top: 0;
	display: flex;
	flex-direction: column;
}

.storeLocator-search-wrapper {
	position: relative;
	padding: 10px;
	background: #f6f6f6;
	border-bottom: 0px solid #D0D0CF;
}

.storeLocator-search-input {
	width: 100%;
	padding: 10px 35px;
	border: 1px solid #D0D0CF;
	font-family: inherit;
	font-size: 16px;
}

.storeLocator-search-input-icon {
	position: absolute;
	font-size: 30px;
	line-height: 30px;
	color: #CBCBCB;
	left: 15px;
	top: 15px;
	z-index: 2;
}

.storeLocator-header {
	background: #f4f4f4;
	color: #7d7d7d;
	border-bottom: 1px solid #d0d0d0;
	font-size: 14px;
	margin: 0;
	padding: 7px 10px 9px;
	position: sticky;
	top: 0;
}

.storeLocator-scroller {
	overflow: auto;
	overscroll-behavior: none;
	max-height: 100vh;
	@supports (max-height: 100svh) {
		// try to use small view height
		// considers mobile device' native interface
		max-height: 100svh
	}
}

.storeLocator-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.storeLocator-list-item {
	display: flex;
	padding: 13px 11px;
	border-bottom: 1px solid #d0d0d0;
	cursor: pointer;
}

.invalid-marker-association {
	cursor: auto;
	background: #ebebeb;
}

.storeLocator-marker-label {
	font-weight: bold;
	color: #757575;
	font-size: 20px;
	margin: 0 12px 0 0;
}

.storeLocator-marker-title {
	margin: 0;
	text-decoration: none;
	font-size: 18px;
	color: #3156C3 !important;
}

.markerLocator-thumbnail {
	display: grid;
	place-items: center;
}

.storeLocator-thumbnail-image {
	width: 48px;
}

.storeLocator-marker-content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin: 0 12px 0 0;
	gap: 3px;
}

.storeLocator-marker-address {
	color: #686868;
	font-style: normal;
}

.storeLocator-marker-distance {
	margin: 0;
	color: #A0A0A0;
}

.storeLocator-no-results-wrapper {
	padding: 0 10px;
}

.storeLocator-no-results-title {
	font-size: 18px;
	color: #8C8C8C;
	margin-bottom: 0;
}

.storeLocator-no-results-message {
	color: #8C8C8C;
}

.storeLocator-no-results-reset-button {
	border: 0;
	font-family: inherit;
	background: transparent;
	color: #36c;
	padding: 0;
	font-size: 15px;
	cursor: pointer;
}

.storeLocator-no-results-reset-button:hover {
	border-bottom: 1px solid #2952a3;
	color: #2952a3;
}

@media screen and (min-width: 769px) {
	.storeLocator-view {
		#mapDiv {
			width: calc(100% - 375px);
			float: right;
		}
	}
}

@media screen and (max-width: 768px) {
	.storeLocator {
		z-index: 99;
		width: 100%;
	}
	.storeLocator-header {
		border-top: 1px solid #d0d0d0;
	}
}
