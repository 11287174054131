.marker-measure-marker-overlay {
	cursor: move;
	border: none;
	position: absolute;

	img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: -15px;
	}
}
