.batchgeo-vertical-resizer {
	height: 10px;
	/* background: $gray; */
	background: #cbcaca;
	color: $black;
	font-size: 12px;
	text-align: center;
	line-height: 1;
	font-weight: bold;
	cursor: row-resize;
	position: absolute;
	width: 100%;
	z-index: 4;
	top: -2px;
	left: 0;
	display: flex;
	justify-content: center;
	z-index: 100;
};

.batchgeo-vertical-arrow-container{
	margin-top: -8px;
	background-color: #fff;
	padding: 3px;
	border-top-left-radius: 11px;
	border-top-right-radius: 11px;
}

.batchgeo-vertical-arrow {
	border: solid black;
	border-width: 0 2px 2px 0;
	display: block;
	padding: 4px;
	margin: 5px;
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
}
