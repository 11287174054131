.batchgeo-contact-owner {
	* {
		box-sizing: border-box
	};

	margin: 0 auto;
	display: block;
	max-width: 342px;
	text-align: left;

	h2 {
		font-size: 27px;
		margin-bottom: 0;
		text-align: center;
	}
	p {
		margin-top: 5px;
		margin-bottom: 30px;
		font-size: 15px;
		text-align: center;
	}
	label {
		display: block;
		font-weight: bold;
		font-size: 12px;
		margin: 18px 0 5px;
	}
	textarea, input {
		width: 100%;
		border: 1px solid #c3c3c3;
		border-radius: 5px;
		padding: 10px;
		font-size: inherit;
		font-family: inherit;
	}
	button {
		width: 100%;
		margin: 30px 0 0 0;
		&:disabled {
			opacity: 0.5;
		}
	}
	textarea {
		min-height: 140px;
	}

	.batchgeo-contact-owner-success {
		@include flexbox;
		@include flex-direction(column);
		@include align-items(center);
		@include justify-content(center);
	}

	.batchgeo-contact-owner-error {
		color: #ff3b47;
		display: none;
		margin: 15px 0 0;
	}
}
