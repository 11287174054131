.context-menu-button {
  display: block;
  width: 48px;
  height: 48px;
  bottom: 30px;
  left: 10px;
  background: #606060;
  background-size: 20px;
  border-radius: 100%;
  position: absolute;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
  &.active {
    background-color: #4a90e2;
  }
  &:before {
    font-family: "batchgeotoolbaricons" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 24px;
    line-height: 0;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    content: "\e91c";
    color: #fff;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    overflow: visible;
    z-index: 1;
  }
}

.in-iframe {
  .context-menu-button {
    width: 32px;
    height: 32px;
    bottom: 10px;
  }
}

.sub-menu-list {
  max-width: 250px;
}

.sub-menu-x-offset {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.context-menu {
  position: absolute;
  z-index: 100;
  transition: bottom 0.25s;
  bottom: -100%;

  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  ul {
    background: #f3f0f2;
    border: 1px solid #cccccc;
    width: 300px;
    padding: 0 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  // NOTE: this cannot be a break point because the menu is triggered via a long
  // press. This long press could be on any touch device of any size.
  &.mobile-menu {
    ul {
      width: 100%;
    }
    li.list-item-context-print {
      display: none;
    }
  }

  li.parent li {
    padding: 16px 15px 16px 10px;
  }

  li {
    font-size: 13px;
    color: #666666;
    padding: 8px 15px 8px 5px;
    cursor: pointer;
    white-space: nowrap;
    border-top: 1px solid #e4e4e4;
    position: relative;
    @include flexbox();
    @include align-items(center);

    &:hover {
      background: #eee;
    }
    &:first-child {
      border-top: none;
    }
    .context-menu-item-icon {
      margin-right: 15px;
      margin-left: 5px;
      font-family: "batchgeotoolbaricons" !important;
      speak: none;
      font-size: 32px;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      height: 32px;
      width: 32px;
      display: block;
      color: #666;
    }
  }

  span.context-menu-item-select.selected {
    &:after {
      content: url("/images/context-selected.png");
      position: absolute;
      right: 20px;
      transform: translateY(-50%);
      // display: inline-block;
      text-align: center;
      // width: 21px;
      //margin-left: -21px;
      color: #4a90e2;
    }
  }

  li.parent {
    &:after {
      content: "";
      position: absolute;
      bottom: 3px;
      right: 3px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 6px 6px;
      border-color: transparent transparent #666666 transparent;
    }
  }

  li.disabled {
    color: #aaa;
    cursor: default;

    &:hover {
      background: inherit;
    }
  }

  &.mobile-menu {
    left: 0px;
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    bottom: 0;
    ul {
      border: 0;
      padding: 0;
    }
    li {
      padding: 6px 49px 6px 10px;
      font-size: 15px;
    }
    li.selected {
      &:before {
        width: 31px;
        margin-left: -31px;
      }
    }
  }
}

.context-menu-overlay {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: opacity 0.25s;
  background: #000;
  opacity: 0;
  z-index: -1;
}
.context-menu-overlay-show {
  display: block;
  z-index: 99;
  opacity: 0.25;
}

.context-embed:before {
  content: "\e90b";
}
.context-edit:before {
  content: "\e916";
}
.context-data:before {
  content: "\e914";
}
.context-heat:before {
  content: "\e918";
}
.context-advanced:before {
  content: "\e917";
}
.context-cluster:before {
  content: "\e919";
}
.context-earth:before {
  content: "\e91a";
}
.context-export:before {
  content: "\e91b";
}
.context-print:before {
  content: "\e909";
}
.context-reset:before {
  content: "\e901";
  font-size: 30px;
}

.context-select-all:before {
  content: "\e91d";
}

.context-zoom:before {
  content: "\e911";
}

.context-copy:before {
  content: "\e91e";
}

.context-direction:before {
  content: "\e922";
}

.context-choropleth:before {
  content: "\e929";
}

.disabled .context-menu-item-icon:before {
  color: #aaa;
}

.iframe-code-container {
  font-family: Arial, sans-serif;
  padding: 12px 24px;
  background-color: #f9f9f9;
  border-radius: 5px;
  max-width: 600px;
  margin: 20px auto;
}

.label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
}

.iframe-code-label {
  font-weight: bold;
  display: block;
}

.iframe-code-textarea {
  width: 100%;
  height: 100px;
  font-family: monospace;
  font-size: 14px;
  color: #333;
  padding: 10px;
  border-radius: 5px;
  resize: none;
}

#copy-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin: 0px;
}

.message {
  font-size: 14px;
  color: green;
  height: 12px;
  margin: 8px 0px;
}
