//Grid unit
$unit: 16px;

@media print {
  body {
    padding: 0;
    margin: 0;
  }
  .batchgeo-side-navigation {
    display: none !important;
  }
  // I don't know why this works but this fixes the missing tiles issue at some
  // window sizes while printing. See: #1553
  #mapDiv div > img {
    position: absolute;
  }

	.container.map-page-container.data-view-open {
		height: auto;
	}
	.rmMarker {
		display: none;
	}
}

body.printfriendly {
  padding: 0;
  margin: 0;
  overflow-y: auto !important;
  overflow-x:hidden;
}

body.force-landscape{
	width: 11in;
	padding: 0;
	margin: 0;
}

#topBar.printfriendly {
  background-color: rgba(255,255,255, 1);
}

#topBar.printfriendly #searchForm {
  display: none;
}

.batchgeo-toolbar.printfriendly .batchgeo-toolbar-button-group {
  display :none;
}

.gmnoprint.printfriendly {
  display: none !important;
}

.gm-svpc.printfriendly {
  display: none;
}

#legWrap.printfriendly {
  background-color: rgba(255,255,255, 1);
  color : rgba(0,0,0, 1);
}

#description.printfriendly{
	color : rgba(0,0,0, 1);
}

#legDiv.printfriendly {
  background-color: rgba(255,255,255, 1);
  color : rgba(0,0,0, 1);
}

/*print-table*/
.batchgeo-print-table {
  width: 100%;
  table-layout: fixed;
}

@mixin printTable(){
  border-left: 1px #fff solid;
  word-break: break-word;
  white-space: normal;
  padding: 3px !important;
  text-align: center;
}

.batchgeo-print-table td {
  @include printTable();
  background-color: #fff;
}

.batchgeo-print-table th {
  @include printTable();
  background-color: #eee;
}

/*print-table list*/
.batchgeo-print-table ul {
  display:block;
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin: 0;
  padding: $unit;
}

.batchgeo-print-table li {
  $line : $unit;
  line-height: $line;
  min-height: $line;
  font-size: $line * 0.75;
  text-decoration: none;
  list-style-type: none;
}

.batchgeo-print-table .printlist-marker-frame {
  position: relative;
  top: $unit;
  right: $unit;
  height: 0;
  overflow: visible;
}

.batchgeo-print-table .printlist-marker {
  font-size: 70px;
  font-weight: bold;
  border: 0;
  padding: 0;
  color: #ccc;
  text-align: right;
}

.batchgeo-print-table .printlist-li-colname{
  font-weight: bold;
}

.batchgeo-print-table .printlist-li-datum{

}
/*Print menu system*/
$backing-color: rgba(247,247,247,0.9);
$backing-color-solid: rgba(247,247,247,1);
$divider-color: rgba(221,221,221,1);
$border-color: rgba(204,204,204,1);
$border-color-active: darken($border-color, 10%);
$border-radius: 5px;
$border-width: 2px;
@mixin borderradius($r) {
	-webkit-border-radius: $r;
	border-radius: $r;
	background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}
@mixin lineargradient($direction, $color-stops...) {
	background: nth(nth($color-stops, 1), 1);
	background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
	background: linear-gradient($direction, $color-stops);
}
@mixin dropshadow(){
	$color: rgba(0, 0, 0, 0.25);
	$x:0;
	$y:10px;
	$blur:20px;
	box-shadow: $x $y $blur $color;
	-moz-box-shadow: $x $y $blur $color;
	-webkit-box-shadow: $x $y $blur $color;
}
@mixin dropshadow-mini(){
	$color: rgba(0, 0, 0, 0.1);
	$x:0;
	$y:5px;
	$blur:10px;
	box-shadow: $x $y $blur $color;
	-moz-box-shadow: $x $y $blur $color;
	-webkit-box-shadow: $x $y $blur $color;
}
@mixin printwindow() {
	background-color: $backing-color;
	border: 1px solid $border-color;
	@include borderradius($border-radius);
}
@mixin printbutton(){
	$gradstart: rgba(251,251,251,1);
	$gradstop: rgba(235,235,235,1);
	$h: $unit;
	@include linear-gradient($gradstart, $gradstop);
	background-color: $backing-color-solid;
	text-transform: uppercase;
	font-size: 14px;
	color: #555555;
	font-weight:700;
	padding: $h*0.5 $h;
	outline: none;
	transition: all .25s ease;
	line-height: 100%;
	border: 1px solid $border-color;
	@include borderradius($border-radius);
}
@mixin printbuttonHover(){
	$gradstart: rgba(235,235,235,1);
	$gradstop: rgba(251,251,251,1);
	@include linear-gradient($gradstart, $gradstop);
}
@mixin titleSize($h){
	text-align: center;
	font-size: $h;
	height: $h*3;
	line-height: $h*3;
	width: 100%;
	display: block;
}



/*buttons*/
.printer-cancel {
	@include printbutton();
	margin: auto;
	width:170px;
}
.printer-cancel:hover {
	@include printbuttonHover()
}
.printer-cancel:active {
	background: $backing-color-solid;
}
/*print-dialog*/
$dialog-padding: 10px;
$divider-color: lighten($border-color, 10%);
@mixin dialogRow(){
	float: left;
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
}
.printer-dialog {
	@include printwindow();
	@include dropshadow();
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 700px;
	padding: 0;
	padding-bottom: 50px;
	z-index: 9999;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.printer-dialog-overlay{
	background: rgba(255,255,255,0.8);
	display:block;
	left:0;right:0;top:0;bottom:0;
	position:absolute;
	z-index: 9998;

}
.printer-dialog-head {
	@include dialogRow();
	border-bottom:1px solid $divider-color;
}
.printer-dialog-title {
	display:flex;
	justify-content: center;
	flex: 1;
	font-weight:500;
	font-size:28px;
	color: rgb(88,87,97);
	margin: 30px 0 35px;
}
.printer-dialog-notice {
	text-align: center;
	color: rgb(88,87,97);
	font-size: 14px;
	margin: auto;
	margin-top: -20px;
	margin-bottom: 30px;
	display:block;
	width: 65%;
}
.printer-dialog-subtitle{
	display:flex;
	justify-content: center;
	font-weight: 700;
	color: rgb(88,87,97);
	font-size:18px;
}
.printer-dialog-category-description{
	text-align: center;
	@include dialogRow();
	color: rgb(88,87,97);
}
.printer-dialog-category-description > p{
	padding: 0 $unit;
	color: rgb(88,87,97);
}
.printer-dialog-body {
	@include dialogRow();
	margin: 35px 0;
}
.printer-dialog-choice-icon {
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	top: 0;
	left : 0;
	right: 0;
	bottom: 0;
	&:hover{
		cursor:pointer;
	}
}
.printer-dialog-choices {
	display:flex;
	justify-content:center;
	padding:10px;
}
.printer-dialog-category {
	@include dialogRow();
	width:50%;
	padding: 0 20px;
	border-left:1px solid $divider-color;
	&:first-child{
		border-left:none;
	}
}
.printer-dialog-choice-frame {
	width:$unit*6;
	height: $unit*6;
	display: block;
	float: left;
	margin: $unit*0.5;
	padding: $unit*0.5;
	@include printwindow();
}
.printer-dialog-category-simple .printer-dialog-choice-frame {
	float:right;
}
.printer-dialog-choice-title {
	position: absolute;
	left: 0;
	right: 0;
	top: 96px;
	padding: 0;
	opacity: 1;
	text-align: center;
	font-size: 12px;
	font-weight:700;
	color: rgb(88,87,97);
}
.printer-dialog-choice {
	background-color: $backing-color-solid;
	position: relative;
	height: 100%;
	width: auto;
	border: 1px solid $border-color;
}
.printer-dialog-choice-description {
	@include dialogRow();
	height: $unit*3;
	font-size: $unit;
	line-height: $unit * 1.5;
	width: 90%;
	margin: $unit*0.5 5%;
	text-align: center;
}
.printer-dialog-choice-description > label {
	padding: 0;
}
.printer-dialog-cancel {
	&:hover{
		cursor:pointer;
	}
}
.printer-dialog-choice-frame:hover {
	border: 1px solid $border-color-active;
	@include dropshadow-mini();
	background-color: #fff;
	&:hover{
		cursor:pointer;
	}
}
.printer-dialog-choice-subtitle{
	text-align:center;
	margin-top:20px;
	display: inline-block;
	width: 100%;
	font-size: 14px;
	color: #585761;
}

/*Progress-bar*/
$bar-height: $unit;
$progress-percent-color: rgba(255,154,31, 1);
$progress-background-color: rgba(226,225,255,1);
$progress-radius: 15px;
.batchgeo-print-table-progressbar-frame {
	position: fixed;
	top: 25%;
	left: 15%;
	right: 15%;
	padding: $unit;
	z-index: 9999;
	@include printwindow();
	@include dropshadow();
}
.table-progressbar-title {
	$h:$unit*2;
	@include titleSize($h);
	@include dialogRow();
	margin-bottom: $unit;
}
.table-progressbar-frame {
	height: $bar-height;
	@include dialogRow();
	width: 90%;
	margin: 0 5%;
}
.table-progressbar-full {
	width: 100%;
	height: 100%;
	background-color: $progress-background-color;
	@include borderradius($progress-radius);
}
.table-progressbar-percent {
	height: 100%;
	width: 0%;
	background-color: $progress-percent-color;
	@include borderradius($progress-radius);
}
.table-progressbar-info-frame {
	@include dialogRow();
	margin-bottom: $unit * 4
}
.table-progressbar-info-percent {
	$h:$unit*1.24;
	@include titleSize($h);
	@include dialogRow();
}
.table-progressbar-info-percent-number{}
.table-progressbar-info-percent-unit{}
.table-progressbar-info-items {
	$h:$unit;
	@include titleSize($h);
	@include dialogRow();
}
.table-progressbar-info-items-thusfar{}
.table-progressbar-info-items-of{}
.table-progressbar-info-items-total{}
.table-progressbar-info-items-items{}
.table-progressbar-cancel {
	position: absolute;
	bottom: $unit*1.5;
	left: 50%;
	transform: translate(-50%, 0);
}
// please wait message
.printer-please-wait {
	@include printwindow();
	@include dropshadow();
	position: absolute;
	text-align: center;
	top: 25%;
	left:25%;
	right: 25%;
	height: $unit*6;
	z-index: 9999
}
.printer-please-wait-label {
	$h:$unit*2;
	@include titleSize($h);
}
