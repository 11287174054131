$border-radius: 0.25rem;
$grey-light: #cbcbcb;
$grey-lighter: #cccccc;
$grey-even-lighter: #dedede;
$grey-lightest: #909090;
$grey: #9b9b9b;

#topBar {
	@include flexbox();
	@include flex();
	@include align-items(center);
	position: absolute;
	width: 100%;
	background-color: rgba(245, 245, 245, 0.9);
	border-bottom: 1px solid #aaa;
	text-transform: none;
	height: auto;
	-webkit-tap-highlight-color: transparent;
	.mapReset {
		display: none;
		margin-top: 0;
	}

	@include media ("screen", "<=550px") {
		.batchgeo-omnibox {
			box-sizing: border-box;
			width: 100%;
		}
	}
}

#topButtons {
	@include flexbox();
	@include flex();
	@include align-items(center);
	height: auto;
	line-height: 1.25em;
	z-index: 99;
	font-size: 15px;
	padding: 10px;
	margin: 0px;
	float: none;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;

	&.open {
		.toggle-mobile-data-view {
			display: none;
			overflow: hidden;
			transition: flex 0.3s ease-out; // note that we're transitioning flex, not height!
			height: auto;
			flex: 1;
			&.hide {
				flex: 0;
			}
		}
	}
}

.right-inner-addon {
	flex-grow: 1;
	position: relative;
	float: none;

	.toolbar-icon-search {
		padding: 0 0 0 0.3rem;
		margin: 0;
		font-size: 30px;
		line-height: 1.25;
		color: $grey-light;
		background-color: none;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
	}
}

#addressBox {
	width: 100%;
	@include border-radius(6px);
	position: relative;
	margin: 0;
	padding: 0.5rem 2rem 0.5rem 2.5rem;
	border: 1px solid $grey-light;
	font-size: 1rem;
	line-height: 1.25;
	border-radius: $border-radius;
	font-family: inherit;
	-webkit-appearance: none;
	outline: none;
	&::-webkit-input-placeholder,
	:-moz-placeholder,
	::-moz-placeholder,
	:-ms-input-placeholder {
		color: $grey-light !important;
	}
	&:focus {
		border-color: $orange;
		background-color: #fffcf9;
	}

	&.open {
		border-radius: 5px 5px 0 0;
	}
}
#addressBox:-webkit-autofill,
#addressBox:-webkit-autofill:hover,
#addressBox:-webkit-autofill:focus,
#addressBox:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px #fffcf9 inset !important;
}

#searchIcon {
	position: absolute;
	right: 8px;
	top: 8px;
	pointer-events: none;
	font-size: 15px;
	padding-left: 6px;
	background-color: #fff;
}

#searchForm {
	@include flex-grow(1);
	@include flexbox();
	@include align-items(center);
	@include justify-content(space-between);
	@include flex-direction(row-reverse);
	overflow: hidden;
	transition: flex 0.2s ease-out; // note that we're transitioning flex, not height!
	transition-delay: 0.1s;
	flex: 1;

	#bgLogo {
		margin-top: 0;
		margin-right: 15px;
	}
	&.active {
		display: block;
	}
}

.ui-autocomplete {
	background: #fff;
	border-radius: 0;
	z-index: 99999 !important;
}

.toggle-mobile-data-view {
	padding-left: 0;
	display: none;
	transition: flex 0.2s ease-out; // note that we're transitioning flex, not height!
	flex: 1;
	overflow: hidden;
	max-width: 105px;
	a {
		opacity: 1;
		transition: opacity 0.2s ease-out; // note that we're transitioning flex, not height!
		-webkit-transition-delay: 0.2s; /* Safari */
		transition-delay: 0.2s;
	}

	&.toggle-mobile-data-view-map-data-buttons {
		margin: 0 -10px 0 0;
	}

	&.hide {
		flex: 0;
		opacity: 0;
		transition: flex 0.2s ease-out; // note that we're transitioning flex, not height!
		margin: 0;
		a {
			opacity: 0;
			transition: opacity 0.1s ease-out; // note that we're transitioning flex, not height!
		}
	}

	a {
		// width: 1rem;
		display: inline-block;
		padding: 10px 10px 10px 10px;
		color: $grey-lightest;
		font-size: 32px;
		text-decoration: none;
		cursor: pointer;
		position: relative;
		&:link,
		&:visited {
			color: $grey-lightest;
		}
		&:first-child:after {
			position: absolute;
			top: 10px;
			right: 0;
			content: "";
			display: block;
			height: 32px;
			width: 1px;
			border-right: 1px solid $grey-even-lighter;
		}
		&.active {
			color: $blue;
		}
	}
}

@-moz-document url-prefix() {
	#searchIcon {
		margin-top: -1px; // firefox only
	}
}

@include media("<=tablet") {
	#topBar {
		background-color: rgba(245, 245, 245, 1);
		height: 60px;
	}
	#topBar {
		.mapReset {
			font-size: 16px;
			font-weight: 400;
		}
	}
	.ui-menu {
		max-height: 175px !important;
	}
}

.storeLocator-view {
	#searchForm {
		display: none;
	}
	@include media("<=tablet") {
		.storeLocator-search-wrapper {
			display: none;
		}
		#searchForm {
			display: block;
		}
	}
}


@include media("<=phone") {
	.toggle-mobile-data-view {
		display: flex;
	}
	.toggle-mobile-data-view {
		display: flex;
	}
	.default-view #searchForm {
		display: block;
	}
	.storeLocator-search-wrapper {
		display: none;
	}
	#topBar {
		.mapReset {
			display: none !important;
		}
	}
}

@include media("screen", ">tablet") {
	#searchForm {
		max-width: 300px;
	}
	.ui-autocomplete {
		max-width: 565px;
	}
	#topButtons {
		@include flex-direction(row-reverse);
	}
	#topBar {
		.mapReset {
			display: flex;
			flex-grow: 0;
		}
	}
}

@media (max-height: 300px) {
	#topBar {
		display: none;
	}
}

* html .ui-autocomplete {
	width: 1px;
	margin-top: -4px !important;
} /* without this, the menu expands to 100% in IE6 */
.ui-menu {
	list-style: none;
	padding: 0;
	margin: 0;
	display: block;
	outline: none;
	position: absolute;
	max-height: 400px;
	overflow: auto;
	border: 1px solid #cbcbcb;
	border-top: none;
	overflow-x: hidden;
	@include box-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
}
.ui-menu .ui-menu-item {
	position: relative;
	margin: 0;
	padding: 0;
	zoom: 1;
	border-bottom: 1px solid #eee;
}
.ui-menu .ui-menu-item a:before {
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	font-size: 30px;
	color: #b2b2b2;
	font-family: "batchgeotoolbaricons" !important;
	position: absolute;
	top: 8px;
	left: 3px;
	content: "\e91f";
	display: block;
	width: 32px;
	height: 32px;
}
.ui-menu .ui-menu-item:last-child {
	border-bottom: none;
}
.ui-menu .ui-menu-divider {
	margin: 5px -2px 5px -2px;
	height: 0;
	font-size: 0;
	line-height: 0;
	border-width: 1px 0 0 0;
}
.ui-menu .ui-menu-item a {
	border: 1px solid transparent;
	text-decoration: none;
	display: block;
	zoom: 1;
	font-size: 13px;
	padding: 15px 12px 15px 38px;
	color: #333;
}
.ui-menu .ui-menu-item a:hover {
	cursor: pointer;
	color: #777;
}
.ui-menu .ui-menu-item a.ui-state-focus,
.ui-menu .ui-menu-item a.ui-state-active {
	border: 1px solid $orange;
	background: #fffcfa;
}
.ui-menu .ui-menu-item a span {
	color: #acacac;
	font-size: 12px;
}
