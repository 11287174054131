.gm-control-active,
.context-menu-button,
.batchgeo-map-owner-trigger-button,
.gm-style-cc {
	cursor: pointer !important;
	a {
		cursor: pointer !important;
	}

	div {
		cursor: pointer !important;
	}
}

#mapDiv a img {
    cursor: pointer !important;
}

.ssQIHO-checkbox-menu-item {
    cursor: pointer !important;
    span {
        cursor: pointer !important;
    }
}

.batchgeo-map-owner-trigger-button

.drawing-manager-is-selecting #mapDiv,
.drawing-manager-is-selecting #mapDiv *,
.drawing-manager-is-selecting #mapDiv *[style] {
  cursor: crosshair;
}

.drawing-manager-button .batchgeo-toolbar-button-icon {
	opacity: 0.55;
}

.drawing-manager-button:hover .batchgeo-toolbar-button-icon {
	opacity: 0.7;
}
.drawing-manager-is-selecting #mapDiv .infoBox a {
    cursor: pointer !important;
}
.drawing-manager-is-selecting #mapDiv .infoBox * {
    cursor: auto !important;
}

.has-children{
	position:relative;
}
.has-children:first-child::after, .has-options::after {
	content: "";
	position:absolute;
	bottom:3px;
	right:3px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 0 6px 6px;
	border-color: transparent transparent #666666 transparent;
}

// fix for #751, this should be looked at more in depth in the future.
#mapDiv > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) > div:nth-child(4){
	z-index: 100 !important;
}
