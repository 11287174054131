$baseURL: "";
$md-breakpoint: 600px;
$xs-breakpoint: 199px;
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600&display=swap");
@import "src/include-media";
@import "src/mixins";
@import "src/icons";
@import "src/menus";
@import "src/map_core";
@import "src/colorbox";
@import "src/buttons";
@import "src/clusterize";
@import "src/table";
@import "src/header";
@import "src/batchgeo-toolbar";
@import "src/batchgeo-optimal-route-toolbar";
@import "src/batchgeo-drawing-manager";
@import "src/batchgeo-keyboard-shortcuts";
@import "src/batchgeo-ui-login-form";
@import "src/batchgeo-ui-map-mode-toggle";
@import "src/batchgeo-mobile-infowindow";
@import "src/batchgeo-geolocation-controls";
@import "src/batchgeo-map-search";
@import "src/batchgeo-sidebar-menu";
@import "src/batchgeo-context-menu.scss";
@import "src/batchgeo-contact-owner";
@import "src/batchgeo-map-tooltip";
@import "src/batchgeo-info-box";
@import "src/batchgeo-store-locator";
@import "src/batchgeo-marker-measure-marker-overlay";
@import "src/batchgeo-vertical-resizer";
@import "src/batchgeo-omnibox";
@import "src/batchgeo-cookie-notice";
@import "src/resource-error-modal";

// SEO: use to describe anchor tags that don't have text content
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

body {
  color: #000;
  font-family: $sans-serif;
  font-size: 14px;
  padding: 0px;
  margin: 0px;
  background-image: none;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.waiting * {
  cursor: wait;
}

a:link,
a:visited {
  color: #00c;
}

h1 {
  margin: 5px 0px 0px 12px;
  padding: 0px;
  float: left;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
  height: 32px;
  line-height: 32px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
}

#bgLogo {
  margin-left: 4px;
  margin-top: -2px;
}

#topBar {
  height: 59px;
}

#settingsButton,
#addDataButton {
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  display: inline-block;
  border-radius: 3px;
  padding: 3px 6px;
  margin-left: 5px;
  white-space: nowrap;
  background: #999;
  background: rgba(0, 0, 0, 0.3);
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow:
    inset 0 -1px 0 rgba(0, 0, 0, 0.3),
    inset 0 1px 0 rgba(255, 255, 255, 0.3);
}

#settingsButton span.text,
#addDataButton span.text {
  margin: 0 3px;
}

#settingsDialog {
  display: none;
  position: absolute;
  font-size: 12px;
  top: 25%;
  left: 50%;
  margin-left: -220px;
  width: 400px;
  padding: 15px 20px;
  background: #eee;
  background-color: rgba(238, 238, 238, 0.9);
  border: 1px solid #999;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);

  h2 {
    font-weight: normal;
    margin-top: 0;
  }

  .option-toggle {
    display: block;
    margin: 0 0 5px 0;
    padding: 2px 0 0 18px;
    line-height: 18px;
  }

  .option-toggle input[type="checkbox"] {
    margin: 0 2px 0 -18px;
    padding: 0;
  }

  .option-toggle select {
    display: inline-block;
    margin: -3px 0;
  }

  .action-buttons {
    text-align: right;
    margin-bottom: 0;
  }
}

#utility a {
  text-decoration: none;
  cursor: pointer;
}

#page {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  text-align: center;
  position: relative;
  background-color: #fff;
}

#data {
  margin: auto;
  background-color: #fff;
  padding: 0 10px;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #fff;
  max-width: 728px;
}
#description {
  text-align: left;
  padding: 9px 4px;
  font-size: 12px;
  max-width: 720px;
  color: #fff;
}
#description a {
  color: #aaaaff;
}
#adWrap {
  width: 100%;
  padding: 0 0;
  position: relative;
}
#topAd {
  overflow: hidden;
  margin: 0px;
  margin: auto;
  width: 100%;
  opacity: 0.8;
  text-align: center;
}

#legWrap {
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 99;
  min-height: 46px;
}

a.header:link,
a.header:visited {
  text-decoration: none;
  color: #000;
}
form {
  display: inline;
}

td.rmMarker {
  display: none;
}

#imageExport {
  text-align: left;
  div {
    margin-bottom: 14px;
  }
  p {
    margin: 0 0 10px 0;
  }
  span {
    display: block;
    margin-left: 6px;
    font-size: 12px;
  }
  .dialog-buttons {
    text-align: center;
    .button {
      width: 101px;
      font-size: 14px;
      padding: 0.5em 1em;
      margin-bottom: 0;
    }
  }
}

/* IMPORTANT */
/* Allows map clusters to be printed*/
* {
  /* Standard */
  print-color-adjust: exact !important;
  /* Chrome, Safari, Edge */
  -webkit-print-color-adjust: exact !important;
  /*Firefox*/
  color-adjust: exact !important;
}

#mapRefresh {
  top: 50px;
  left: 50px;
}

.advancedToggle {
  font-size: 12px;
  cursor: pointer;
  padding-left: 27px;
}

.advancedToggle.on {
  padding-left: 0;
}

.advancedToggle.on:before {
  content: "\2714";
  display: inline-block;
  font-size: 10px;
  width: 27px;
  left: 10px;
  position: relative;
}

// Hidden unless on mobile
.batchgeo-side-navigation {
  display: none;
}

@include media("screen", "<=200px") {
  #topBar,
  #bgLogo {
    display: none;
    height: 0;
  }
}

@media only screen and (max-width: 250px), screen and (max-height: 250px) {
  // Only for iframes because if its a browser window you can resize it or
  // if you're on mobile when keyboards pop open it can hide the search
  body.in-iframe {
    #topBar,
    #bgLogo {
      display: none;
      height: 0;
    }
  }
}

@media only screen and (max-height: 350px), screen and (max-width: 200px) {
  #legWrap {
    display: none;
    //height: 0;
  }
  #descriptionWrap {
    display: none;
  }
  #adWrap {
    display: none;
  }
  #bgLogo {
    display: none;
    height: 0;
  }
}

@include media("screen", "<=599px") {
  h1 {
    font-size: 13px;
    font-weight: bold;
  }
  #legDiv {
    font-size: 10px;
  }
  #description {
    font-size: 11px;
  }
  html {
    overflow: hidden;
  }
  #topAd {
    width: 100%;
    height: 64px;
  }
}

@include media("screen", "<=550px") {
  .locator {
    #topButtons {
      width: 100%;
    }
  }

  .batchgeo-side-navigation {
    display: block;
  }

  .right-inner-addon {
    width: 100%;
  }
}

.batchgeo-map-owner-trigger-button {
  position: absolute;
  bottom: 14px;
  right: 0;
  font-family: $sans-serif;
  font-size: 10px;
  color: #444444;
  border: 0;
  padding-right: 6px;
  padding-left: 6px;
  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.grecaptcha-badge {
  opacity: 0;
}

.loading-text {
  color: #676767;
}

.loader {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 50px;
  height: 50px;
  background-color: $orange;
  border-radius: 50%;

  &:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    border: 0px solid white;
    transform: translate(-50%, -50%);
    animation: loading 1000ms ease-out forwards infinite;
  }
}

.infowindow-upper-arrow {
  top: -4px;
  position: absolute;
  margin-left: 50%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(255, 255, 255, 0.9);
}

@keyframes loading {
  0% {
    border: 0px solid white;
  }

  20% {
    border: 8px solid white;
    width: 0%;
    height: 0%;
  }

  100% {
    border: 8px solid white;
    width: 100%;
    height: 100%;
  }
}

@media all and (max-width: 768px) {
  #bgLogo,
  #utility {
    display: none;
  }
}

.iframe-scrollable {
  height: 100vh;
  overflow-y: scroll;
}

@import "src/print";
