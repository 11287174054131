.keyboard-shortcuts {
  @include flexbox;
  @include justify-content(space-between);
  table {
    table-layout: fixed;
  }
  td {
    padding: 10px 5px;
  }
  .key-break {
    margin-left: 0;
    @media (min-width: 450px) {
      display: block;
      margin-top: 10px;
    }
  }
  .keyboard-shortcuts-left,
  .keyboard-shortcuts-right {
    width: 47.5%;
  }
  .keyboard-shortcuts-left {
    td:first-child {width: 25px}
    td:last-child {width: 100px}
  }
  .keyboard-shortcuts-right {
    td:first-child {width: 50px}
    td:last-child {width: 90px}
  }
  @media (max-width: 450px) {
    overflow: scroll;
    max-height: 435px;
    @include flex-direction(column);
    .keyboard-shortcuts-left,
    .keyboard-shortcuts-right {
      width: 100%;
    }
  }
}
