/******************************************************************
BatchGeo
Stylesheet: Tables (Desktop & Mobile)
******************************************************************/

table {
	font-size: 0.9em;
	border-spacing: 0;
	border-collapse: separate;
	background-clip: padding-box;
	width: 100%;
}

td {
	padding: 10px 15px;
	border-bottom: 1px solid #e0e0e0;
	background: #fff;
	transition: 0.2s background-color;
	white-space: nowrap;
	&[data-type="date"] {
		text-align: center;
	}
	&[data-type="number"] {
		text-align: right;
	}
	&[data-type="string"] {
		text-align: left;
	}
}

td:not(:last-of-type) {
	border-right: 1px solid #e0e0e0;
}

td a {
	color: #36c;
	text-decoration: none;
}

td a:hover {
	text-decoration: underline;
}

th, td {
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.row-select-selecting, .row-select-selecting td {
	cursor: default;
	cursor: cell !important;
}

.batchgeo-table td::selection, .batchgeo-table td *::selection {
	background: transparent; /* WebKit/Blink Browsers */
}

.batchgeo-table td::-moz-selection, .batchgeo-table td *::-moz-selection {
	background: transparent; /* Gecko Browsers */
}

thead {
	background: #f5f5f5;
}

tr.selected {
	border-left: 3px solid #4285F4;
}

tr.selected td {
	background-color: #ECF3FF;
}

.row-no-marker td {
	background-color: #efefef;
	color: #444;
}

.sort-arrow {
	padding: 2px;
	font-size: 0.6rem;
	position: absolute;
	right: 5px;
	color: #666;
}

.clusterize {
	background: #f5f5f5;
}

// Ensures that widths between the faux table for sticky header th's and real
// table th's are actually the same widths. See the render() method docs in
// BatchGeoTable for more info
.clusterize * {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.clusterize-faux-thead {
	border-bottom: 1px solid #e0e0e0;
}

.hidden {
	display: none;
}

// Making this opacity 0 and the border transparent below hide the hidden table
// header below the faux table in Safari which lets you scroll past top:0
.clusterize-thead {
	opacity: 0;
}
.clusterize-thead th {
	border: 1px solid transparent;
	padding-top: 0;
	padding-bottom: 0;
	line-height: 0;
}

.clusterize-table {
	@media screen and (max-width: 600px) {
		margin-bottom: 164px;
	}
	margin-left: 1px;
}

th {
	padding: 10px 15px;
	border-left: 1px solid #e0e0e0;
	white-space: nowrap;
	position: relative;
}

th:first-child {
	border: none;
}

#dataView {
	margin: 0 auto;
	padding: 0;
	width: 100%;
	text-align: center;
	position: relative;
	background-color: transparent;
	@media screen and (max-width: $md-breakpoint) {
		position: absolute;
		top: 58px;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 99;
		border-top:1px solid #CCCCCC;
	}
	.table-resizer {
		@media screen and (max-width: $md-breakpoint) {
			display: none;
		}
	}
}

.batchgeo-table {
	@media screen and (max-width: $md-breakpoint) {
		height: calc(100vh - 43px);
	}
}

//trying to add space for batchgeo-vertical-resizer
#mapWrap + #dataView {
	padding-top: 6px;
}

// styling for data table tooltip
th,td {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 20px;
}

td {
	max-width: 25vw;
}

.data-table-width-restrict {
	th {
		max-width: 25vw;
	}
}

th:hover {
	overflow: visible;
	text-overflow: wrap;
	white-space: normal;
	overflow-wrap: break-word;
}

tr {
	position: relative;
}

.data-table-cell-tooltip:before {
  content: attr(data-text);
  position: absolute;
  margin: 0 0 0 -15px;
  top: 0;
  white-space: normal;
  max-width: calc(25vw - 18px);
  padding: 10px 15px;
  border-radius:10px;
  background: inherit;
  z-index: 1;
  display:none;
}

.data-table-cell-tooltip-link:before {
	color: #36c;
	text-decoration: underline;
}

.data-table-cell-tooltip[data-type='string']:hover:before {
  display:block;
}

// firefox hack
@-moz-document url-prefix() {
	.selected {
		.data-table-cell-tooltip:before {
			margin: 0 0 0 -12px;
		}
	}
}
