.batchgeo-mobile-infowindow-wrapper {
	width: 100%;
	bottom: 0;
	z-index: 999;
	color: #545453;


	.batchgeo-mobile-infowindow-content {
		@include flexbox;
		@include flex-direction(column);
		padding: 0px 15px;
	}

	.pg {
		background: #f6f6f6;
		font-size: 14px;
		z-index: inherit;
		text-align: left;
		border-bottom: 1px solid #d4d4d4;
		padding: 6px 15px;
		font-weight: bold;
		color: #858585;
		@include flexbox;
		@include align-items(center);
		.pg-arrow {
			font-size: 24px;
			height:32px;
			padding: 0 6px;
			margin: 0;
			background: #fefefe; /* Old browsers */
			background: linear-gradient(to bottom, #fefefe 0%,#f6f6f6 100%);
			color: #979797;
			border: 1px solid #d4d4d4;
			border-radius: 4px;
			&:before{
				font-family: 'batchgeotoolbaricons' !important;
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				content: "\e923";
				font-size: 36px;
				color: #979797;
				position:relative;
				top:-3px;
			}
			&:active {
				background: linear-gradient(to bottom, #fefefe 100%, #f6f6f6 0%);
			}
		}
		.icon-keyboard-arrow-left {
			margin-left: 20px;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		.icon-keyboard-arrow-right {
			position: relative;
			left: -1px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			&:before{
				content: "\e924";
			}
		}
	}

	.batchgeo-mobile-infowindow-inner {
		border-top: 1px solid #d4d4d4;
		&.ios-iframe {
			display: none;
			@include transform(translateY(0%) !important);
		}
		&.animate {
			@include transition(250ms bottom cubic-bezier(1, 0, 0, 1), 250ms transform cubic-bezier(1, 0, 0, 1));
		}
		&.detail-open {
			bottom: 45px !important;
			@include transition(250ms bottom cubic-bezier(1, 0, 0, 1));
		}

		position: fixed;
		bottom: 0;
		@include transform(translateY(110%));
		width: 100%;
		padding: 0;
		background: #fff;
		box-sizing: border-box;

		@include flexbox;
		@include flex-direction(column);

		h1 {
			color: #387cbc;
			font-size: 18px;
			margin: 0;
		}

		address {
			margin: 0 0 13px 0;
			font-style: normal;
			font-size: 13px;
		}

		.l {
			color: #545453;
			font-weight: bold;
		}

		.batchgeo-mobile-infowindow-description-url {
			margin-top: 8px;
			padding: 4px 12px 4px 28px;
			font-weight: bold;
			font-size: 12px;
			text-transform: uppercase;
			border: 1px solid #d9d9d9;
			border-radius: 3px;
			color: #878787;
			background: url(/images/link.svg) no-repeat 5px center #fefefe; /* Old browsers */
			background: url(/images/link.svg) no-repeat 5px center, -moz-linear-gradient(top, #fefefe 0%, #f7f7f7 100%); /* FF3.6-15 */
			background: url(/images/link.svg) no-repeat 5px center, -webkit-linear-gradient(top, #fefefe 0%,#f7f7f7 100%); /* Chrome10-25,Safari5.1-6 */
			background: url(/images/link.svg) no-repeat 5px center, linear-gradient(to bottom, #fefefe 0%,#f7f7f7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

			&:active, &:focus {
				background: url(/images/link.svg) no-repeat 5px center #f5f5f5;
			}
		}

		.batchgeo-mobile-infowindow-address-link {
			color: inherit;
			text-decoration: none;
		}

		.batchgeo-mobile-infowindow-content {
			margin: 15px 0 0 0;
		}

		.batchgeo-mobile-infowindow-content-inner {
			margin: 17px 0 0 0;
			padding: 0 15px 15px 15px;
			line-height: 1.5;
			max-height: 35vh;
			overflow: auto;
		}

		&.ios-iframe .batchgeo-mobile-infowindow-content-inner {
			margin: 0;
		}

		.batchgeo-mobile-infowindow-close-button {
			@include transition(250ms top cubic-bezier(1, 0, 0, 1));
			position: absolute;
			right: 10px;
			top: 0;
			background: #f5f5f5;
			border: 1px solid #d9d9d9;
			width: 44px;
			height: 44px;
			text-align:center;
			border-radius: 100%;
			color: #878787;
			box-shadow: rgba(0, 0, 0, 0.05) 0 2px 1px;
			font-family: $sans-serif;
			font-size: 36px;
			line-height: 0;
			padding: 0;
			&:before {
				font-family: 'batchgeotoolbaricons' !important;
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				content: "\e912";
				font-size: 36px;
				color: #979797;
			}

			// If there is no title this will be the first line in the infowindow. We add some space to the right so the text
			// doesn't go under the close button
			& + address {
				width: calc(100% - 40px);
			}
		}
	}
	.batchgeo-mobile-infowindow-open {
		&.ios-iframe {
			@include flexbox;
		}
		@include transform(translateY(0%) !important);

		.batchgeo-mobile-infowindow-close-button {
			top: -24px;
		}
	}
	.batchgeo-mobile-infowindow-show {
		bottom: 0 !important;
	}

	.batchgeo-mobile-infowindow-footer {
		display: none;
		position: fixed;
		bottom: 0;
		right: 0;
		left: 0;
		border-top: 1px solid #d9d9d9;
		height: 45px;
		@include transition(250ms bottom cubic-bezier(1, 0, 0, 1));

		@include justify-content(space-between);
		@include align-items(center);

		&.info-window-footer-show {
			@include flexbox;
		}

		.infowindow-footer-button {
			position: relative;
			height: 45px;
			color: #878787;
			text-decoration: none;
			text-transform: uppercase;
			font-weight: bold;
			letter-spacing: 1px;
			font-size: 14px;
			background: #fefefe; /* Old browsers */
			background: -moz-linear-gradient(top, #fefefe 0%, #f7f7f7 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, #fefefe 0%,#f7f7f7 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, #fefefe 0%,#f7f7f7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

			@include flexbox;
			@include justify-content(center);
			@include align-items(center);

			&:active, &:focus {
				background: #f5f5f5;
			}
			&.btn-col-3 { width: 33.3%; }
			&.btn-col-2 { width: 50%; }
			&.btn-col-1 { width: 100% }

			&:before {
				display: block;
				content: "";
				width: 32px;
				height: 32px;
				text-align:center;
				background-size: 14px;
				background-position: center;
				font-family: 'batchgeotoolbaricons' !important;
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				content: "\e922";
				font-size: 36px;
				color: #979797;
				margin-right:8px;
				margin-top:-4px;
			}
			&.info-button {
				&:before {
					content: "\e925";
				}
			}
			&.url-button {
				&:before {
					content: "\e921";
				}
			}
			&.nav-button {
				&:before {
					content: "\e922";
				}
			}
			&:after {
				content: '';
				color: #d9d9d9;
				position: absolute;
				right: 0;
				top: 8px;
				border-right: 1px dotted #CCCCCC;
				height: 25px;
			}
			&:last-child {
				border-bottom-right-radius: 5px;
				&:after {
					content: '';
					display:none;
				}
			}
		}
	}

	.batchgeo-mobile-infowindow-sub-content {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

@media screen and (max-width: 300px) {
	.in-iframe {
		.batchgeo-mobile-infowindow-footer {
			.infowindow-footer-button {
				line-height: 0;
				font-size: 0;
				color: transparent;
				padding-left:5px;
				&:before{
					font-size: 36px;
					color: #979797;
				}
			}
		}
	}
}
