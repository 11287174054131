/* max-height - the only parameter in this file that needs to be edited.
 * Change it to suit your needs. The rest is recommended to leave as is.
 */
.clusterize-scroll{
  height: 50vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
	@media screen and (max-width: $md-breakpoint) {
		height: 100%;
	}
}

/* By default extra tag .clusterize-keep-parity added to keep parity of rows.
 * Useful when used :nth-child(even/odd)
 */
.clusterize-extra-row.clusterize-keep-parity{
  display: none;
}

/* During initialization clusterize adds tabindex to force the browser to keep focus
 * on the scrolling list, see issue #11
 * Outline removes default browser's borders for focused elements.
 */
.clusterize-content{
    outline: 0;
}

.clusterize-headers {
  overflow: hidden;
}

.clusterize-no-data, .clusterize-no-data td {
	border: 0;
	color: #ddd;
	letter-spacing: -1px;
	text-align: center;
	border-left: 8px solid transparent;
}
