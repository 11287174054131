.batchgeo-ui-login-form {
	display: block;
	background: rgba(255, 255, 255, 0.2);
	padding: 20px;

	.batchgeo-ui-login-form-submit-wrapper {
		margin-top: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	a {
		font-size: 11px;
	}

	label {
		padding-top: 10px;
		display: block;

		&:first-child {
			padding-top: 0;
		}
	}

	[type="email"],
	[type="password"],
	[type="text"] {
		border: 1px solid rgba(252, 189, 66, 0.6);
		margin-top: .7em;
		border-radius: 2px;
		padding: .7em 1em;
		width: 100%;
		box-sizing: border-box;
	}

	[type="submit"] {
		padding: 8px 20px;
		margin-bottom: 0px;
		font-size: 13px;
	}
}
