.batchgeo-omnibox {
	position: relative;

	.batchgeo-omnibox-clear-button {
		position: absolute;
		top: 0px;
		right: 0px;
		border: 0;
		background: transparent;
		height: 100%;
		padding: 0 10px;
		color: #9E9E9E;
		font-family: helvetica;
		font-weight: lighter;

		&:before {
			content:"\00d7";
			line-height: 15px;
			font-size: 30px;
		}
		&:hover:before {
			color: #666;
			cursor: pointer;
		}
	}
}