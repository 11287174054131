.pac-container {
  z-index: 99999;
}

#optimal-route-form-container {
  padding: 60px 40px 40px 40px;
  .button {
    align-self: center;
    margin-bottom: 0;
    margin-top: 15px;
  }
  .distanceInput {
    align-self: center;
    margin: 0px;
  }
  .form_row {
    font-size: 1.1em;
    width: 100%;
    display: flex;
    align-items: stretch;
    height: 15%;
    justify-content: space-around;
    margin-bottom: 25px;
    @media screen and (max-width: 599px) {
      flex-direction: column;
    }
  }
  .search_field_wrap {
    width: 70%;
    flex-direction: row;
    display: flex;
    @media screen and (max-width: 599px) {
      width: 100%;
    }
  }
  input {
    padding: 0.5rem 0.75rem 0.5rem 2rem;
    border: 1px solid $grey-light;
    font-size: 1rem;
    line-height: 1.25;
    font-family: inherit;
    -webkit-appearance: none;
    outline: none;
    position: relative;
    z-index: 1;
    width: 100%;
    &::-webkit-input-placeholder {
      color: $grey-light !important;
    }
    &::-moz-placeholder {
      color: $grey-light !important;
    }
    &::-moz-placeholder {
      color: $grey-light !important;
    }
    &::-ms-input-placeholder {
      color: $grey-light !important;
    }
    &:focus {
      border-color: $orange;
      background-color: #fffcf9;
    }
  }
  label {
    color: #585757;
    width: 25%;
    @media screen and (max-width: 599px) {
      width: 100%;
      margin-bottom: 5px;
    }
  }
  .search_icon {
    margin-left: -40px;
  }
  .search_icon:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "batchgeotoolbaricons" !important;
    font-size: 36px;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e90f";
    position: relative;
    width: 40px;
    height: 40px;
    right: -40px;
    top: 2px;
    display: inline-flex;
    z-index: 2;
    color: $grey-light !important;
  }
}

.optimal-route-form {
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.batchgeo-toolbar-button-group {
  border-width: 0px;
  border-radius: 6px;
  background-color: transparent;
}
.batchgeo-toolbar-button-group .copied {
  background: white;
  border-width: 0px;
  border-radius: 6px;
}

.grey-out {
  background-color: lightgrey;
  &:hover {
    background-color: lightgrey;
    cursor: default;
  }
}

.directions {
  .instructions {
    margin-top: 0;
  }

  .distance {
    margin: 0;
    font-style: italic;
    font-size: 0.8em;
  }
}
.optimal-route-toolbar {
  border-radius: 6px;
  background: white;
  .batchgeo-toolbar-button-group {
    :first-child {
      box-shadow: none;
      border-width: 0px;
      border-radius: 6px;
    }
  }
}
.button-copy-rows {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.optimal-route-toolbar button {
  display: flex !important;
  width: auto !important;
  border-width: 0px;
  border-radius: 6px;
  padding: 0 15px 0 10px;
  font-size: 1em;
  font-size: 13px;
  color: #666666;
  cursor: pointer;
  white-space: nowrap;
  border-top: 1px solid #e4e4e4;
  @include flexbox();
  @include align-items(center);
  .batchgeo-toolbar-button-icon {
    margin-right: 5px;
  }
}
.optimal-route-toolbar {
  .batchgeo-toolbar-button-group .batchgeo-toolbar-button:first-child {
    box-shadow: none;
    border-radius: 6px;
    border: none;
  }
  .routing-button:hover {
    border: none;
    background-color: #acbdc359;
  }
}
.optimal-route-toolbar {
  border: none;
  height: 48px;

  :disabled {
    border-color: transparent;
    background-color: #f0f2f3;
    color: #b0b0b0;
  }
}
.optimal-route-toolbar
  .batchgeo-toolbar-button-group:hover
  .batchgeo-toolbar-button {
  border: none;
}
.optimal-route-toolbar .routing-container {
  display: flex;
  flex-shrink: 0;
  align-items: stretch;
  background-color: #e4eff3;
  color: #6f6f6f;
  border-bottom: solid;
  border-top-width: 0px;
  border-bottom-width: 3px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-color: #024059;
  border-radius: 6px;
  outline: none;
  overflow: hidden;
}
.optimal-route-toolbar .routing-section {
  border: none;
  padding: 0px 32px 0px 32px;
  background-color: #e4eff3;
  color: #6f6f6f;
  font-size: 12px;
  line-height: 150%;
  border-radius: 6px;
  :hover {
    border: none;
  }
  .batchgeo-toolbar-button-icon {
    display: none;
  }
  .grey-out {
    border-color: transparent;
    background-color: #f0f2f3;
    color: #b0b0b0;
  }
}
.optimal-route-toolbar .routing-header {
  padding: 0px 10px 0px 10px;
  color: #024059;
  align-self: center;
}

.optimal-route-toolbar .routing-spacers {
  border-color: #acbdc3;
  background-color: #acbdc3;
  flex-shrink: 0;
  width: 0px;
  border: 2px;
  padding: 1px;
  height: auto;
  margin-top: 4px;
  margin-bottom: 4px;
  @media screen and (max-width: 599px) {
    width: 100%;
    height: 0px;
  }
}

.optimal-route-toolbar .grey-out {
  border-color: transparent;
  background-color: #f0f2f3;
  color: #b0b0b0;
}
.autocomplete-description {
  font-size: 0.8em;
  font-style: italic;
}

.ui-corner-all p {
  margin: 0;
}
